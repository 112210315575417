import React, { lazy, Suspense } from 'react';
import {
  ChakraProvider,
  Box,
  Grid,
  Spinner
} from '@chakra-ui/react';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import theme from './theme'
import NavBar from './components/Navbar'

const Home = lazy(() => import('./screens/home'));
const Lost404 = lazy(() => import('./screens/404'));
const Attestation = lazy(() => import('./screens/attestation'));
const Newsletter = lazy(() => import('./screens/newsletter'));
const QrCodeGenerator = lazy(() => import('./screens/qrCodeGenerator'));

function App() {
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <NavBar>
          <Box fontSize="xl">
            <Grid p={3}>
              <Suspense fallback={<Spinner size='xl' />}>
                <Routes>
                  <Route index element={<Home />} />
                  <Route path='newsletter' element={<Newsletter />} />
                  <Route path='attestation' element={<Attestation />} />
                  <Route path='qrcodegen' element={<QrCodeGenerator />} />
                  <Route path='*' element={<Lost404 />} />
                </Routes>
              </Suspense>
            </Grid>
          </Box>
        </NavBar>
      </BrowserRouter>
    </ChakraProvider >
  );
}

export default App;
